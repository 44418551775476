.editor {
  width: 672px;
}

.editor h2,
.editor h3,
.editor h4 {
  font-weight: bold;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.editor h2 {
  font-size: 1.5em;
}

.editor h3 {
  font-size: 1.4em;
}

.editor h4 {
  font-size: 1.3em;
}

.editor li {
  list-style: disc;
  margin-left: 20px;
}

.editor .ck-content figcaption {
  background: none;
  color: #7e7e7e;
  text-align: left;
}

.editor a {
  color: blue;
}
