@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* 
@font-face {
  font-family: "Poetsen One";
  font-weight: 400;
  font-display: swap;
  src: url(../public/fonts/PoetsenOne-Regular.ttf) format("ttf");
} */
/* 
@font-face {
  font-family: "RifficFree";
  font-weight: 800;
  font-display: swap;
  src: url(/fonts/RifficFree-Bold.ttf) format("ttf");
} */

:root {
  --fc-shadow-header: 0px 1px 2px rgba(0, 0, 0, 0.13),
    0px 1px 8px rgba(0, 0, 0, 0.15);
  --fc-color-primary-blue: #042675;
  --fc-color-light-blue: #0f3dab;
  --fc-color-accent-3: #0c48d7;
  --fc-color-gray-bg: #f9f9f9;
  --fc-color-black: #222222;
  --fc-color-gray-1: #f4f4f4;
  --fc-color-accent-4: #7e7e7e;
  --fc-color-1: #fd4943;
  --fc-red: #fd4943;
  --fc-color-3: #fda500;
  --fc-color-bg-gray: #fafbfc;
  --fc-green-action: #5caa0b;
  --fc-divide-lightblue: #e6eefd;
  --fc-lightblue-box: #f3f6fd;
  --fc-lightblue-header: #eff6ff;
  --fc-text-banner-gray: #434361;
  --fc-text-banner-red: #e30074;
}

@layer utilities {
  .clip-style {
    clip-path: polygon(100% 0%, 95% 51%, 100% 100%, 0 100%, 0% 50%, 0 0);
  }

  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .custom-file-input {
    /* display: none; */
  }

  .custom-file-input::placeholder {
    text-align: center !important;
    opacity: 1;
  }

  .custom-file-input::-webkit-file-upload-button {
    display: none;
  }
}

@layer components {
  .tabs-active {
    @apply text-sm md:text-base px-8 py-2 border-[1px] border-[#BABABA] border-b-[0px] rounded-t-sm relative before:content-[""] before:absolute before:left-0 before:-bottom-[.2rem] before:w-full before:h-[0.7rem] before:bg-white font-bold;
  }

  .tabs-unactive {
    @apply px-8 font-normal text-sm md:text-base;
  }

  .menu-items-table-container {
    @apply w-full px-4 py-4 flex items-center hover:bg-[#F1F1F1] cursor-pointer font-light text-sm;
  }
}

.ReactCrop__child-wrapper {
  width: 100%;
  height: 100%;
}

.custom-input .MuiOutlinedInput-root {
  background: white;
}

.custom-checkbox {
  margin-bottom: auto;
}

.custom-checkbox svg {
  fill: white;
}

.bg-pattern {
  background-image: url("../public/assets/bg-pattern-1.png");
  background-repeat: no-repeat;
  object-fit: contain;
  background-position: top center;
  background-size: auto 90%;
}

.bg-pattern-2 {
  background-image: url("../public/assets/bg-pattern-2.png");
  background-repeat: no-repeat;
  object-fit: contain;
  background-position: bottom center;
  background-size: auto 100%;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
